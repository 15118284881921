
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { ActivityListItem, ActivityTypes } from '@/definitions/shared/templates/activity/types'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'

export default defineComponent({
  components: {
    TmEllipsis,
  },
  props: {
    items: {
      type: Array as PropType<ActivityListItem[]>,
      required: true,
    },
  },
  setup() {
    const getActivityInfo = (type: ActivityTypes) => {
      switch (type) {
        case 'mobileChat': return { typeText: 'Mobile chat', link: { name: 'base.chats' } }
        case 'email': return { typeText: 'Email threads', link: { name: 'base.chats' } }
        default: return { typeText: '', link: { name: 'base.chats' } }
      }
    }

    return {
      getActivityInfo,
    }
  },
})
