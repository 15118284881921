<template>
  <div class="right-sidebar-button">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.right-sidebar-button {
  height: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: $border-radius;
  border: 1px dashed $input;
  justify-content: center;
  font-weight: $semi-bold-font;
  color: $primary;
  margin-bottom: 16px;
  transition: $transition-03s;

  &:hover {
    background: $lift;
  }

  &:deep(.on-left) {
    margin-right: 6px;
  }
}
</style>
