
import { defineComponent, computed } from 'vue'
import ChatNote from '@/components/shared/chat/ChatNote.vue'
import DetailsSidebarButton from '@/components/shared/templates/details/sidebar/DetailsSidebarButton.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    ChatNote,
    DetailsSidebarButton,
  },
  props: {
    search: {
      type: String,
    },
    addNoteText: {
      type: String,
      default: 'Add contact note',
    },
  },
  setup(props) {
    const { openModal } = useModals()
    const notesList = [
      {
        author: 'John Doe',
        text: '857 If you’re an absolute beginner looking for some help getting started, or a Sketch veteran...',
        date: '4:15 pm',
      },
      {
        author: 'John Doe',
        text: 'If you’re an absolute beginner looking for some help getting started, or a Sketch veteran...',
        date: '4:15 pm',
      },
      {
        author: 'John Doe',
        text: 'If you’re an absolute beginner looking for some help getting started, or a Sketch veteran...',
        date: '4:15 pm',
      },
    ]
    const notes = computed(() => props.search ? notesList.slice(0, 1) : notesList)

    return {
      openModal,
      notes,
    }
  },
})
