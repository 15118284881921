import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pointer d-flex align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_field = _resolveComponent("tm-field")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_field, {
      "model-value": _ctx.isAllSelected,
      type: "checkbox",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggleAllSelected')))
    }, null, 8, ["model-value"]),
    _createElementVNode("div", {
      class: "body-text-semi-bold-14 lh-20 emphasize--text ml-2",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('toggleAllSelected')))
    }, " Select all ")
  ]))
}