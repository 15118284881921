import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a0621f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "activities-list" }
const _hoisted_2 = { class: "activities-list__date" }
const _hoisted_3 = {
  key: 0,
  class: "activities-list__type"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_ellipsis = _resolveComponent("tm-ellipsis")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: i,
        class: _normalizeClass(["activities-list__item", {
        [`activities-list__item--${item.status}`]: item.status,
      }])
      }, [
        _createVNode(_component_router_link, {
          to: _ctx.getActivityInfo(item.type).link,
          class: "activities-list__title"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_tm_ellipsis, { inline: "" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.title), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["to"]),
        _createElementVNode("div", _hoisted_2, [
          _createTextVNode(_toDisplayString(item.date) + " ", 1),
          (_ctx.getActivityInfo(item.type).typeText)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.getActivityInfo(item.type).typeText), 1))
            : _createCommentVNode("", true)
        ])
      ], 2))
    }), 128))
  ]))
}