import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d0e198c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chat-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chats_sidebar = _resolveComponent("chats-sidebar")!
  const _component_chats_content = _resolveComponent("chats-content")!
  const _component_chats_details = _resolveComponent("chats-details")!
  const _directive_touch_swipe = _resolveDirective("touch-swipe")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["chat", {
      'chat--details-collapsed': !_ctx.showChatDetails || !_ctx.activeChat,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["chat-wrapper", {
          'chat-wrapper--active': _ctx.showMobileChat,
        }])
      }, [
        _createVNode(_component_chats_sidebar),
        _withDirectives(_createVNode(_component_chats_content, null, null, 512), [
          [
            _directive_touch_swipe,
            _ctx.handleSwipe,
            void 0,
            { right: true }
          ]
        ]),
        _createVNode(_component_chats_details)
      ], 2)
    ])
  ], 2))
}