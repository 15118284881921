
import type { PropType } from 'vue'
import { defineComponent, computed, ref } from 'vue'
import ChatAvatar from '@/components/shared/chat/ChatAvatar.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import TmButton from '@/components/shared/TmButton.vue'
import Highlight from '@/components/shared/Highlight.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmButtonMore from '@/components/shared/TmButtonMore.vue'
import ChatPreviewDropdown from '@/components/shared/chat/chatPreview/ChatPreviewDropdown.vue'
import type { ChatSenderType, ChatLogItemType, ChatTypes } from '@/definitions/shared/chat/types'
import type { PersonType, TextArray } from '@/definitions/shared/types'
import { formatUntilOneDay } from '@/services/dateTimeService'
import { useBreakpoints } from '@/compositions/breakpoints'
import { randomIntFromInterval } from '@/services/utils'
import { useRoute } from 'vue-router'
import { useChatsModals, useSidebar } from '@/compositions/chats/chats'

type PreviewMessageType = null | {
  text?: TextArray | string;
  class?: string;
}

export default defineComponent({
  components: {
    Highlight,
    TmButton,
    TmButtonMore,
    ChatPreviewDropdown,
    TmDropdown,
    TmEllipsis,
    ChatAvatar,
  },
  props: {
    showSelection: {
      type: Boolean,
    },
    channel: {
      type: String as PropType<'email' | 'outlook' | 'meta' | 'instagram' | 'mobile' | 'liveChat'| 'liveChatLive'>,
    },
    chatSender: {
      type: Object as PropType<ChatSenderType>,
    },
    pinned: {
      type: Boolean,
    },
    active: {
      type: Boolean,
    },
    notifications: {
      type: Number,
    },
    openChat: {
      type: Boolean,
    },
    selected: {
      type: Boolean,
    },
    chatLog: {
      type: Array as PropType<ChatLogItemType[]>,
    },
    search: {
      type: String,
      default: '',
    },
    hideDate: {
      type: Boolean,
    },
    closed: {
      type: Boolean,
    },
    previewText: {
      type: String,
    },
    type: {
      type: String as PropType<ChatTypes>,
    },
    subject: {
      type: String,
    },
    members: {
      type: Array as PropType<PersonType[]>,
    },
  },
  emits: ['update:selected', 'update:pinned'],
  setup(props: any) {
    const { isMdMax } = useBreakpoints()
    const { solveChat } = useChatsModals()
    const dropdownState = ref<boolean>(false)
    const isSmMaxActionsVisible = ref(false)
    const route = useRoute()
    const formattedDate = computed((): string => {
      if (!props.chatLog?.length) {
        return 'Just now'
      }

      return formatUntilOneDay(props.chatLog?.slice().pop().date, 'D MMM', true)
    })
    const searchMessage = computed((): ChatLogItemType => props.chatLog.find((e: ChatLogItemType) => {
      if (e.text && props.search && e.type === 'message') {
        return `${e.text}`.toLowerCase().indexOf(props.search.toLowerCase()) !== -1
      }

      return false
    }))

    const theLastMessageType = computed(() => props.chatLog?.slice().pop().callType)
    const previewNotifications = computed(() => {
      if (route.name === 'base.chats.unread') {
        return randomIntFromInterval(1, 4)
      } else if (props.type === 'live') {
        return ['missed', 'waiting'].includes(theLastMessageType.value) ? randomIntFromInterval(1, 4) : 0
      } else {
        return props.notifications
      }
    })

    const lastMessage = computed<ChatLogItemType>(() => props.chatLog?.slice().pop())
    const isPreviewNotificationsGray = computed(
      () => props.type === 'live' && lastMessage.value.callType === 'missed'
    )

    const { getActiveFilter } = useSidebar()
    const previewBtn = computed(() => {
      const filterName = getActiveFilter.value.name

      if (filterName === 'waiting') {
        if (theLastMessageType.value === 'missed') {
          return {
            text: 'Solve',
            icon: 'edit',
            clickAction: solveChat,
          }
        } else if (theLastMessageType.value === 'waiting') {
          return {
            text: 'Take it',
            icon: 'drafts',
          }
        }
      }

      return false
    })

    const computedPreview = computed((): PreviewMessageType => {
      if (props.previewText) {
        return { text: props.previewText }
      }

      if (!props.chatLog.length) {
        return { text: '' }
      }

      if (searchMessage.value) {
        return { text: searchMessage.value.text }
      }

      const previewClassMap = {
        live: {
          missed: 'error--text font-weight-medium',
          waiting: 'font-weight-bold emphasize--text',
        },
        call: {
          missed: 'error--text',
        },
      }

      if (lastMessage.value.callType) {
        const missedMessages = [
          'Hello',
          'I have a question',
          'Can you help me?',
        ]
        // live
        if (props.type === 'live') {
          const personName = props.chatSender.person?.fullName || 'User'
          switch (lastMessage.value.callType) {
            case 'missed': return { class: previewClassMap.live.missed, text: 'Missed request' }
            case 'waiting': return { class: previewClassMap.live.waiting, text: missedMessages[randomIntFromInterval(0, missedMessages.length - 1)] }
            case 'open': return { text: lastMessage.value.text }
            case 'ended': return { text: `${personName} left this chat at 11:49 am` }
            case 'solved': return { text: `${personName} left this chat at 11:49 am` }
          }
        // call
        } else {
          switch (lastMessage.value.callType) {
            case 'missed': return { class: previewClassMap.call.missed, text: 'Missed call' }
            case 'ended': return {
              text: lastMessage.value.outcoming ? 'Outbound call 00:01:32' : 'Inbound call 00:02:32',
            }
          }
        }
      }

      return { text: lastMessage.value.text }
    })

    const computedPreviewClass = computed(() => {
      return computedPreview.value?.class || (previewNotifications.value ? 'emphasize--text' : 'distinct--text')
    })

    const updateMobileActions = (value: boolean) => {
      isSmMaxActionsVisible.value = value
    }
    const channelIcon = computed(() => {
      switch (props.channel) {
        case 'meta': return require('@/assets/images/chats/meta.svg')
        case 'mobile': return require('@/assets/images/chats/whatsapp.svg')
        case 'outlook': return require('@/assets/images/chats/message.svg')
        case 'email': return require('@/assets/images/chats/message.svg')
        case 'instagram': return require('@/assets/images/chats/instagram-bordered.svg')
        case 'liveChatGlobal': return require('@/assets/images/chats/live-chat-global.svg')
        default: return require('@/assets/images/chats/chat.svg')
      }
    })

    return {
      channelIcon,
      updateMobileActions,
      dropdownState,
      isMdMax,
      formattedDate,
      computedPreview,
      computedPreviewClass,
      isSmMaxActionsVisible,
      theLastMessageType,
      previewNotifications,
      isPreviewNotificationsGray,
      previewBtn,
    }
  },
})
