
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    isAllSelected: {
      type: Boolean,
    },
  },
  emits: ['toggleAllSelected'],
})
