
import { defineComponent } from 'vue'
import ChatsSidebar from '@/components/pages/chats/ChatsSidebar.vue'
import ChatsContent from '@/components/pages/chats/ChatsContent.vue'
import { useChats } from '@/compositions/chats/chats'
import { useBreakpoints } from '@/compositions/breakpoints'
import ChatsDetails from '@/components/pages/chats/ChatsDetails.vue'
// import ChatsSecondarySidebar from '@/components/pages/chats/ChatsSecondarySidebar.vue'

export default defineComponent({
  components: {
    // ChatsSecondarySidebar,
    ChatsSidebar,
    ChatsDetails,
    ChatsContent,
  },
  setup() {
    const {
      getActiveChat: activeChat,
      showMobileChat,
      showChatDetails,
    } = useChats()
    const { isMdMax } = useBreakpoints()

    const handleSwipe = () => {
      showMobileChat.value = false
    }

    return {
      isMdMax,
      showChatDetails,
      handleSwipe,
      activeChat,
      showMobileChat,
    }
  },
})
