
import type { PropType } from 'vue'
import { ref, computed, defineComponent } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import { useChatsModals, useSidebar } from '@/compositions/chats/chats'
import type { ChatCallTypes } from '@/definitions/shared/chat/types'

export default defineComponent({
  components: {
    TmDropdownItem,
  },
  props: {
    pinned: {
      type: Boolean,
    },
    messageType: {
      type: String as PropType<ChatCallTypes>,
    },
  },
  emits: ['update:pinned'],
  setup(props) {
    const { deleteChat, assignChat, solveChat, rejectChat } = useChatsModals()
    const { getActiveFilter } = useSidebar()

    const isUnread = ref(false)
    const isMuted = ref(false)

    const changeWithDelay = (item: boolean) => {
      setTimeout(() => {
        item = !item
      }, 500)
    }

    const otherFilters = [
      'open',
      'unread',
      'unassigned',
      'assigned',
      'allChats',
    ]

    const isReject = computed(() => props.messageType === 'waiting' && getActiveFilter.value.name === 'waiting')
    const isAddNote = computed(() => props.messageType === 'missed' && getActiveFilter.value.name === 'waiting')
    const isCreateTask = computed(() => props.messageType === 'missed' && getActiveFilter.value.name === 'waiting')
    const isMarkAsUnread = computed(() => otherFilters.includes(getActiveFilter.value.name))
    const isSolve = computed(() => otherFilters.includes(getActiveFilter.value.name))
    const isPin = computed(() => otherFilters.includes(getActiveFilter.value.name))
    const isDelete = computed(() => !(getActiveFilter.value.name === 'waiting'))
    const isMute = computed(() => otherFilters.includes(getActiveFilter.value.name))
    const isReopen = computed(() => getActiveFilter.value.name === 'solved')

    return {
      solveChat,
      assignChat,
      deleteChat,
      rejectChat,
      getActiveFilter,
      isReject,
      isAddNote,
      isCreateTask,
      isMarkAsUnread,
      isSolve,
      isPin,
      isDelete,
      isMute,
      isReopen,
      isUnread,
      isMuted,
      changeWithDelay,
    }
  },
})
