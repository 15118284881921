import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-368957be"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "details-sidebar-notes" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_details_sidebar_button = _resolveComponent("details-sidebar-button")!
  const _component_chat_note = _resolveComponent("chat-note")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_details_sidebar_button, {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openModal('note')))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_tm_icon, {
          name: "add",
          left: "",
          size: "small"
        }),
        _createTextVNode(" " + _toDisplayString(_ctx.addNoteText), 1)
      ]),
      _: 1
    }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notes, (item, i) => {
      return (_openBlock(), _createBlock(_component_chat_note, _mergeProps({ key: i }, item, { search: _ctx.search }), null, 16, ["search"]))
    }), 128))
  ]))
}