import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isPin)
      ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
          key: 0,
          icon: _ctx.pinned ? 'tmi-unpin' : 'tmi-pin',
          label: _ctx.pinned ? 'Unpin' : 'Pin',
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:pinned')))
        }, null, 8, ["icon", "label"]))
      : _createCommentVNode("", true),
    (_ctx.isMute)
      ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
          key: 1,
          icon: _ctx.isMuted ? 'volume_on' : 'volume_off',
          label: _ctx.isMuted ? 'Unmute' : 'Mute',
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeWithDelay(_ctx.isMuted)))
        }, null, 8, ["icon", "label"]))
      : _createCommentVNode("", true),
    (_ctx.isReopen)
      ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
          key: 2,
          icon: "refresh",
          label: "Reopen"
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_tm_dropdown_item, {
      icon: "tmi-person-assign",
      label: "Assign",
      onClick: _ctx.assignChat
    }, null, 8, ["onClick"]),
    (_ctx.isMarkAsUnread)
      ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
          key: 3,
          icon: _ctx.isUnread ? 'drafts' : 'mark_as_unread',
          label: _ctx.isUnread ? 'Mark as read' : 'Mark as unread',
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeWithDelay(_ctx.isUnread)))
        }, null, 8, ["icon", "label"]))
      : _createCommentVNode("", true),
    (_ctx.isSolve)
      ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
          key: 4,
          icon: "task_alt",
          label: "Mark as solved",
          onClick: _ctx.solveChat
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true),
    (_ctx.isReject)
      ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
          key: 5,
          icon: "cancel",
          label: "Reject",
          onClick: _ctx.rejectChat
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true),
    (_ctx.isAddNote)
      ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
          key: 6,
          icon: "tmi-add-note",
          label: "Add note"
        }))
      : _createCommentVNode("", true),
    (_ctx.isCreateTask)
      ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
          key: 7,
          icon: "tmi-task-check",
          label: "Create task"
        }))
      : _createCommentVNode("", true),
    (_ctx.isDelete)
      ? (_openBlock(), _createBlock(_component_tm_dropdown_item, {
          key: 8,
          icon: "delete",
          label: "Delete chat",
          onClick: _ctx.deleteChat
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}