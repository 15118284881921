
import type { PropType } from 'vue'
import { defineComponent, computed, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useModals } from '@/compositions/modals'
import DetailsSidebarButton from '@/components/shared/templates/details/sidebar/DetailsSidebarButton.vue'
import ActivitiesList from '@/components/shared/templates/activity/ActivitiesList.vue'
import type { ActivityListItem } from '@/definitions/shared/templates/activity/types'

export default defineComponent({
  components: {
    TmButton,
    DetailsSidebarButton,
    ActivitiesList,
  },
  props: {
    activities: {
      type: Array as PropType<ActivityListItem[]>,
      default: () => [],
    },
    visibleActivitiesCount: {
      type: Number,
      default: 5,
    },
  },
  setup(props) {
    const { openModal } = useModals()

    const visibleActivitiesCountValue = ref(props.visibleActivitiesCount)

    const activitiesSorted = computed(() => props.activities.slice().sort((a: ActivityListItem) => a.status === 'open' ? -1 : 1))
    const visibleActivities = computed(() => activitiesSorted.value.slice(0, visibleActivitiesCountValue.value))
    const isShowMoreVisible = computed(() => visibleActivitiesCountValue.value < props.activities.length)

    const openActivities = computed(() => visibleActivities.value.filter((e: ActivityListItem) => e.status === 'open'))
    const pastActivities = computed(() => visibleActivities.value.filter((e: ActivityListItem) => e.status === 'past'))

    return {
      isShowMoreVisible,
      visibleActivities,
      visibleActivitiesCountValue,
      activitiesSorted,
      openActivities,
      pastActivities,
      openModal,
    }
  },
})
